import {INotificationPhoneConfirmation} from "./SmsNotificationConstants";

export class NotificationActions {
    public static readonly EDIT_CANCEL = "EDIT_CANCEL_NOTIFICATION";
    public static readonly REPORT_CHANGE_PERIOD = "REPORT_CHANGE_PERIOD_NOTIFICATION";
    public static readonly REPORT_CHANGE_TYPE = "REPORT_CHANGE_TYPE";
    public static readonly REPORT_TIME_ZONE_CHANGE = "REPORT_TIME_ZONE_CHANGE";
    public static readonly REPORT_TOGGLE = "REPORT_TOGGLE_NOTIFICATION";
    public static readonly REPORT_BATTERY_READINGS_TOGGLE = "REPORT_BATTERY_READINGS_TOGGLE";
    public static readonly EMAIL_TOGGLE = "EMAIL_TOGGLE_NOTIFICATION";
    public static readonly GET_SETTINGS = "GET_SETTINGS_NOTIFICATION";
    public static readonly GET_SETTINGS_SUCCESS = "GET_SETTINGS_NOTIFICATION_SUCCESS";
    public static readonly GET_SETTINGS_FAILED = "GET_SETTINGS_NOTIFICATION_FAILED";
    
    public static readonly EDIT = "EDIT_NOTIFICATION";
    public static readonly EDIT_SUCCESS = "EDIT_NOTIFICATION_SUCCESS";
    public static readonly EDIT_FAILED = "EDIT_NOTIFICATION_FAILED";

    public static readonly SHOW_PHONES_LIST = "SHOW_PHONES_LIST";
    public static readonly CLOSE_PHONES_LIST = "CLOSE_PHONES_LIST";

    public static readonly SHOW_COMPANY_FORM = "SHOW_COMPANY_FORM";
    public static readonly CLOSE_COMPANY_FORM = "CLOSE_COMPANY_FORM";
    
    
    public static readonly SHOW_EMAIL_LIST = "SHOW_EMAIL_LIST";
    public static readonly CLOSE_EMAIL_LIST = "CLOSE_EMAIL_LIST";

    public static readonly LANGUAGE_CHANGE = "LANGUAGE_CHANGE";

    public static readonly UNREGISTER_MOBILE_REQUEST = "UNREGISTER_MOBILE_REQUEST";
    public static readonly UNREGISTER_MOBILE_SUCCESS = "UNREGISTER_MOBILE_SUCCESS";
    public static readonly UNREGISTER_MOBILE_FAILURE = "UNREGISTER_MOBILE_FAILURE";
}

export interface TimeZoneOption {
    id: string;
    displayName: string;
}

export interface ICompanyInfo {
    companyName: string;
    taxNumber: string;
    address: string;
}

export interface INotificationSettings {
    summaryReportPeriod?: number;
    summaryReportType: ReportTypes;
    includeBatteryReadings: boolean;
    notifyEmail: string;
    notifyEmailEnabled: boolean;
    notifySmsEnabled: boolean;
    language: string;
    selectedTimeZoneId: string;

}

export interface INotificationViewSettings extends INotificationSettings, INotificationPhoneConfirmation {
    userEmail: string;
    lastRegisteredDevice: string;
    numberOfPremiumDevices: number;
    phoneConfirmed: boolean;
    smsCounter: number;
    timeZones: TimeZoneOption[];
}

export enum ReportTypes {
    Xlsx = 1,
    Pdf = 2
}

export enum ReportPeriodTime {
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}