import * as React from "react";
import {useEffect} from "react";
import {Button} from "react-bootstrap";
import {Typeahead} from 'react-bootstrap-typeahead';
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import ScrollArea from "react-scrollbar";
import {bindActionCreators} from "redux";
import {NotificationActionCreators} from "actions/NotificationActionCreators";
import {INotificationSettings, ReportPeriodTime, ReportTypes, TimeZoneOption} from "constants/NotificationConstants";
import Radio from "../../controles/Radio";
import Switch from "../../controles/Switch";
import "../../less/Notification.css";
import {IAppStore} from "store/configureStore";
import {INotification} from "store/NotificationStore";
import UserContext, {IUserContext} from '../UserContext';
import SubmitButton from "../SubmitButton";
import PhoneNumbersList from "./PhoneNumbersList";
import EmailList from "./EmailList";
import LanguageDropDown from "./LangaugeDropDown";
import ReportPeriodSelect from "./ReportPeriodSelect";
import TimeZoneSelector from "./TimeZoneSelector";
import MobileAppSettings from "./MobileAppSettings";
import ReportCompanyForm from "./ReportCompanyForm";

interface INotificationProps extends INotification {
    getUserSettings: () => void;
    reportPeriodChange: (value: number) => void;
    reportToggleChange: () => void;
    reportBatteryReadingsChange: () => void;
    languageSelectChange: (language: string) => void;
    emailToggleChange: () => void;
    showPhonesListDialog: () => void;
    closePhonesListDialog: () => void;
    showEmailListDialog: () => void;
    closeEmailListDialog: () => void;
    saveUserSettings: (settings: INotificationSettings) => void;
    reportTypeChange: (type: ReportTypes) => void;
    reportTimeZoneChange: (timeZoneId: string) => void;
    cancelChanges: () => void;
    unRegisterMobileDevices: () => void;
    closeReportCompanyFormDialog: () => void;
    showReportCompanyFormDialog: () => void;
}

const Notification = (props: INotificationProps) => {
    const intl = useIntl();
    const userContext = React.useContext<IUserContext>(UserContext);
    if (!userContext.isAuthenticated)
        return (<Navigate to="/portal"/>);

    useEffect(() => {
        props.getUserSettings();
    }, []);

    const onSummaryReportTypeChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = Number(target.value);
        props.reportTypeChange(value);
    }

    const isNoPremiumDevices = () => props.numberOfPremiumDevices === 0;
    const isDisabled = () => isNoPremiumDevices() || userContext.isDemoUser;

    const handleSummaryReportClick = (e: any) => {
        e.preventDefault();
        window.location.href = `api/Report/Summary/${props.summaryReportPeriod}/${props.summaryReportType}?timeZoneInfoId=${props.selectedTimeZoneId}`;
    };
    const handleSaveUserSettingsClick = () => {
        props.saveUserSettings({
            summaryReportPeriod: props.summaryReportPeriod,
            summaryReportType: props.summaryReportType,
            notifyEmail: props.userEmail,
            notifyEmailEnabled: props.emailEnabled,
            includeBatteryReadings: props.includeBatteryReadings,
            notifySmsEnabled: props.smsEnabled,
            language: props.language ?? "en",
            selectedTimeZoneId: props.selectedTimeZoneId
        });
    };
    const handleLanguageChange = (eventKey: string) => props.languageSelectChange(eventKey);

    return (
        <div className="notification-section">
            <ScrollArea className="notification-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>
                                <i className="material-icons">email</i> {" "}
                                <FormattedMessage id="notifications.title"/>
                            </h3>
                        </div>
                    </div>
                    {
                        isNoPremiumDevices() ?
                            <div className="alert alert-danger">
                                <FormattedMessage id="notifications.info"/>
                            </div>
                            :
                            <div className="alert alert-info">
                                <FormattedMessage id="notifications.info"/>
                            </div>
                    }
                    <LanguageDropDown
                        handleLanguageChange={handleLanguageChange}
                        language={props.language}
                        isDisabled={isDisabled()}
                    />
                    <div className="row content">
                        <div className="col-md-12"><h4><FormattedMessage id="notifications.summary.report"/></h4></div>
                    </div>
                    <ReportPeriodSelect
                        reportPeriodChange={props.reportPeriodChange}
                        reportToggleChange={props.reportToggleChange}
                        isDisabled={isDisabled()}
                        summaryReportEnabled={props.summaryReportEnabled}
                        summaryReportPeriod={props.summaryReportPeriod}
                        userEmail={props.userEmail}
                    />
                    <div className="row content">
                        <div className="col-sm-4">
                            <div className="input-group center-element" style={{height: "60px"}}>
                                <span> <FormattedMessage id="notifications.summary.report.type.title"/>: &nbsp; </span>
                                <Radio name="summary-report-type" value={ReportTypes.Xlsx}
                                       checked={props.summaryReportType === ReportTypes.Xlsx}
                                       onChange={onSummaryReportTypeChange}>
                                    <a className="icon-png xlsx"
                                       title={intl.formatMessage({id: `notifications.summary.report.type.${ReportTypes[ReportTypes.Xlsx].toLowerCase()}`})}>
                                        &nbsp;
                                    </a>
                                </Radio>
                                <Radio name="summary-report-type" value={ReportTypes.Pdf}
                                       checked={props.summaryReportType === ReportTypes.Pdf}
                                       onChange={onSummaryReportTypeChange}>
                                    <a className="icon-png pdf"
                                       title={intl.formatMessage({id: `notifications.summary.report.type.${ReportTypes[ReportTypes.Pdf].toLowerCase()}`})}>
                                        &nbsp;
                                    </a>
                                </Radio>
                            </div>
                            <div className="input-group center-element" style={{height: "60px"}}>
                                <span> <FormattedMessage
                                    id="notifications.summary.report.battery-readings.title"/>: &nbsp; </span>
                                <Switch
                                    onChange={props.reportBatteryReadingsChange}
                                    checked={props.includeBatteryReadings}
                                    disabled={isDisabled()}
                                />
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="row content">
                                <div className="col-sm-9">
                                    <Button
                                        disabled={props.summaryReportPeriod === null || (!props.isSaved) || isDisabled()}
                                        onClick={handleSummaryReportClick}>
                                        <i className="material-icons">get_app</i> <span><FormattedMessage
                                        id="notifications.summary.download"/></span>
                                    </Button>
                                </div>
                                <div className="col-sm-3">
                                    <Button
                                        disabled={props.summaryReportPeriod === null || (!props.isSaved) || isDisabled()}
                                        onClick={props.showReportCompanyFormDialog}>
                                        <span><FormattedMessage id="notifications.summary.company.form"/></span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TimeZoneSelector
                        timeZones={props.timeZones}
                        selectedTimeZoneId={props.selectedTimeZoneId}
                        reportTimeZoneChange={props.reportTimeZoneChange}/>
                    <div className="row content">
                        <div className="col-md-12"><h4><FormattedMessage id="notifications.email.title"/></h4></div>
                    </div>
                    <div className="row content">
                        <div className="col-sm-4 flex-box">
                            <Button
                                className="pull-right"
                                disabled={isDisabled()}
                                onClick={props.showEmailListDialog}>
                                <i className="material-icons">email</i>
                                <FormattedMessage id="notifications.email.list"/>
                            </Button>
                        </div>
                        <div className="col-sm-8">
                            <FormattedMessage id="notifications.email.description"/>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-md-12"><h4><FormattedMessage id="notifications.phone.title"/></h4></div>
                    </div>
                    <div className="row content">
                        <div className="col-sm-8">
                            <FormattedMessage id="notifications.phone.description"
                                              values={{smsNumber: <strong>{props.smsCounter}</strong>}}/>
                        </div>
                        <div className="col-sm-4">
                            <Button
                                className="pull-right"
                                disabled={isDisabled()}
                                onClick={props.showPhonesListDialog}>
                                <i className="material-icons">phone</i>
                                <FormattedMessage id="notifications.phone.list"/>
                            </Button>
                        </div>
                    </div>
                    <div className="row content separator">
                        <div className="col-sm-12 save-buttons">
                            <SubmitButton className="pull-right" disabled={props.isSaved || isDisabled()}
                                          onClick={handleSaveUserSettingsClick}>
                                <i className="material-icons">save</i> <FormattedMessage id="notifications.save"/>
                            </SubmitButton>
                            {" "}
                            <Button
                                className="pull-right"
                                disabled={props.isSaved || isDisabled()}
                                onClick={props.cancelChanges}>
                                <i className="material-icons">cancel</i> <FormattedMessage
                                id="notifications.cancel"/>
                            </Button>
                        </div>
                    </div>
                    <MobileAppSettings
                        unRegisterMobileDevices={props.unRegisterMobileDevices}
                        isDisabled={isDisabled()}
                        lastRegisteredDevice={props.lastRegisteredDevice}/>
                </div>
                {props.showPhonesList ?
                    <PhoneNumbersList closePhonesListDialog={props.closePhonesListDialog}
                                      disabled={props.numberOfPremiumDevices == 0}/>
                    : null
                }
                {props.showReportCompanyForm ?
                    <ReportCompanyForm closeReportCompanyForm={props.closeReportCompanyFormDialog}
                                       disabled={props.numberOfPremiumDevices == 0}/>
                    : null
                }
                {props.showEmailList ?
                    <EmailList closeEmailListDialog={props.closeEmailListDialog}
                               disabled={props.numberOfPremiumDevices == 0}/>
                    : null
                }
            </ScrollArea>
        </div>
    )
}

export default connect(
    (state: IAppStore) => state.notification,
    (dispatch: any) => bindActionCreators(NotificationActionCreators, dispatch)
)(Notification);