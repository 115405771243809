import {FormattedMessage, useIntl} from "react-intl";
import * as React from "react";
import {Button, Form, FormControl, Modal, Spinner} from "react-bootstrap";
import UserContext, {IUserContext} from "../UserContext";
import {useEffect, useState} from "react";
import SubmitButton from "../SubmitButton";
import notificationAPI, {NotificationAPI} from "../../api/NotificationAPI";
import {promiseResult} from "../../actions/promiseDispatch";
import {ICompanyInfo} from "../../constants/NotificationConstants";

interface IModelState {
    message: string;
    companyName: string;
    taxNumber: string;
    address: string;
    isLoaded: boolean;
}

const ReportCompanyForm = (props: {
    closeReportCompanyForm: () => void,
    disabled: boolean,
}) => {

    const [model, setModel] = useState<IModelState>({
        isLoaded: false,
        message: null,
        address: null,
        companyName: null,
        taxNumber: null
    });

    useEffect(() => {
        promiseResult<ICompanyInfo>(
            notificationAPI.getCompanyInfo,
            (companyInfo: ICompanyInfo) => {
                setModel({
                    message: null,
                    isLoaded: true,
                    companyName: companyInfo.companyName,
                    taxNumber: companyInfo.taxNumber,
                    address: companyInfo.address
                });
            },
            () => {
                setModel({
                    isLoaded: true,
                    message: "notifications.summary.report.company-info.cant.be.read",
                    companyName: null,
                    address: null,
                    taxNumber: null
                });
            });
    }, []);

    const handleSavingCompanyForm = () => {
        notificationAPI.saveCompanyInfo({
            companyName: model.companyName,
            taxNumber: model.taxNumber,
            address: model.address
        })
            .then((response: Response) => response.status)
            .then((result: number) => {
                if (result === 204)
                    props.closeReportCompanyForm();
                setModel({...model, message: "notifications.summary.report.company-info.cant.be.saved"});
            });
    }

    const handleInputChange = (event: React.FormEvent<any>) => {
        const target = event.target as any;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        switch (target.name) {
            case "companyName":
                setModel({...model, companyName: value.toString()});
                break;
            case "taxNumber":
                setModel({...model, taxNumber: value.toString()});
                break;
            case "address":
                setModel({...model, address: value.toString()});
                break;
            default:
                break;
        }
    }

    const userContext = React.useContext<IUserContext>(UserContext);
    const intl = useIntl();

    return (
        <Modal centered show={true} className={"report-company-data-dialog " + userContext.style}
               onHide={props.closeReportCompanyForm}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="notifications.summary.report.company-info.form"/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {model.isLoaded
                    ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <Form noValidate>
                                    <div className="title-label">
                                        <FormattedMessage id="notifications.summary.report.company-name"/>
                                    </div>
                                    <div>
                                        <FormControl
                                            className="form-control-inline"
                                            placeholder={intl.formatMessage({id: "notifications.summary.report.company-name"})}
                                            type="text"
                                            name="companyName"
                                            value={model.companyName !== null ? model.companyName : ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="title-label">
                                        <FormattedMessage id="notifications.summary.report.address"/>
                                    </div>
                                    <div>
                                        <FormControl
                                            className="form-control-inline"
                                            placeholder={intl.formatMessage({id: "notifications.summary.report.address"})}
                                            type="text"
                                            name="address"
                                            value={model.address !== null ? model.address : ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="title-label">
                                        <FormattedMessage id="notifications.summary.report.taxNumber"/>
                                    </div>
                                    <div>
                                        <FormControl
                                            className="form-control-inline"
                                            placeholder={intl.formatMessage({id: "notifications.summary.report.taxNumber"})}
                                            type="text"
                                            name="taxNumber"
                                            value={model.taxNumber !== null ? model.taxNumber : ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Form>
                                {model.message ?
                                    <div className="error-message">
                                        <FormattedMessage id={model.message}/>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    )
                    : (
                        <div style={{textAlign: "center", width: "100%"}}>                           
                                <Spinner animation="grow"/>                           
                        </div>)}

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeReportCompanyForm}>
                    <FormattedMessage id="notifications.cancel"/>
                </Button>
                <SubmitButton onClick={handleSavingCompanyForm}>
                    <FormattedMessage id="notifications.save"/>
                </SubmitButton>
            </Modal.Footer>
        </Modal>);
}

export default ReportCompanyForm;