import {NotificationActions, INotificationViewSettings, ReportPeriodTime, ReportTypes, TimeZoneOption} from 'constants/NotificationConstants';


export interface INotification {
    isSaved: boolean;
    message?: string;
    summaryReportEnabled: boolean;
    summaryReportPeriod?: ReportPeriodTime;
    summaryReportType: ReportTypes;
    includeBatteryReadings: boolean;
    numberOfPremiumDevices: number;
    userEmail: string;
    lastRegisteredDevice: string;
    emailEnabled: boolean;
    smsEnabled: boolean;
    smsCounter: number;
    showPhonesList: boolean;
    showEmailList: boolean;
    showReportCompanyForm: boolean;
    language: string;
    selectedTimeZoneId: string;
    timeZones: TimeZoneOption[];
}

const initialState: INotification = {
    isSaved: true,
    message: null,
    summaryReportEnabled: false,
    summaryReportPeriod: null,
    summaryReportType: ReportTypes.Xlsx,
    userEmail: null,
    lastRegisteredDevice: null,
    emailEnabled: false,
    numberOfPremiumDevices: null,
    includeBatteryReadings: false,
    smsEnabled: false,
    smsCounter: 0,
    language: null,
    showPhonesList: false,
    showEmailList: false,
    showReportCompanyForm: false,
    timeZones: null,
    selectedTimeZoneId: null
};

let lastSavedState: INotification = { ...initialState };
export const reducer = (state: INotification, action: any): INotification => {
    state = state || initialState;
    switch (action.type) {
        case NotificationActions.LANGUAGE_CHANGE:
            return { ...state, language: action.payload, isSaved: false };
        case NotificationActions.REPORT_CHANGE_PERIOD:
            return {
                ...state,
                isSaved: false,
                message: null,
                summaryReportEnabled: true,
                summaryReportPeriod: action.payload
            };
        case NotificationActions.REPORT_CHANGE_TYPE:
            return {
                ...state,
                isSaved: false,
                message: null,
                summaryReportType: action.payload
            };
        case NotificationActions.REPORT_TOGGLE:
            const newSummaryReportEnabled = !state.summaryReportEnabled;
            return {
                ...state,
                isSaved: false,
                message: null,
                summaryReportEnabled: newSummaryReportEnabled,
                summaryReportPeriod: newSummaryReportEnabled ? ReportPeriodTime.Monthly : null,
            };
        case NotificationActions.REPORT_BATTERY_READINGS_TOGGLE:
            return {
                ...state,
                isSaved: false,
                message: null,
                includeBatteryReadings: !state.includeBatteryReadings
            };
        case NotificationActions.EMAIL_TOGGLE:
            return {
                ...state,
                isSaved: false,
                message: null,
                emailEnabled: !state.emailEnabled
            };
        case NotificationActions.REPORT_TIME_ZONE_CHANGE:
            return {
                ...state,
                isSaved:false,
                message:null,
                selectedTimeZoneId: action.payload
            }
        case NotificationActions.EDIT:
            return state;
        case NotificationActions.EDIT_SUCCESS:
            return {
                ...state,
                isSaved: true
            };
        case NotificationActions.EDIT_FAILED:
            return {
                ...state,
                isSaved: false,
                message: action.payload
            };
        case NotificationActions.GET_SETTINGS:
            return state;
        case NotificationActions.EDIT_CANCEL:
            return { ...lastSavedState };
        case NotificationActions.SHOW_PHONES_LIST:
            return { ...state, showPhonesList: true };            
        case NotificationActions.CLOSE_PHONES_LIST:
            return { ...state, showPhonesList: false };
        case NotificationActions.SHOW_COMPANY_FORM:
            return { ...state, showReportCompanyForm: true };
        case NotificationActions.CLOSE_COMPANY_FORM:
            return { ...state, showReportCompanyForm: false };
        case NotificationActions.SHOW_EMAIL_LIST:
            return { ...state, showEmailList: true };
        case NotificationActions.CLOSE_EMAIL_LIST:
            return { ...state, showEmailList: false };
        case NotificationActions.UNREGISTER_MOBILE_SUCCESS:
            return {
                ...state,
                lastRegisteredDevice: null
            };
        case NotificationActions.GET_SETTINGS_SUCCESS:
            const payload = action.payload as INotificationViewSettings;
            lastSavedState = {
                isSaved: true,
                message: state.message,
                summaryReportEnabled: payload.summaryReportPeriod != null,
                summaryReportPeriod: payload.summaryReportPeriod,
                summaryReportType: payload.summaryReportType ?? ReportTypes.Xlsx,
                userEmail: payload.userEmail,
                includeBatteryReadings: payload.includeBatteryReadings,
                lastRegisteredDevice: payload.lastRegisteredDevice,
                emailEnabled: payload.notifyEmail != null,
                numberOfPremiumDevices: payload.numberOfPremiumDevices,
                smsCounter: payload.smsCounter,
                smsEnabled: payload.notifySmsEnabled,
                language: payload.language,
                timeZones: payload.timeZones,
                selectedTimeZoneId: payload.selectedTimeZoneId,
                showPhonesList: false,
                showEmailList: false,
                showReportCompanyForm: false,
            };
            return { ...lastSavedState };
        default:
            return state;
    }
};