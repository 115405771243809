import NotificationAPI from "../api/NotificationAPI";
import {
    NotificationActions,
    INotificationSettings,
    INotificationViewSettings,
    ReportTypes } from "../constants/NotificationConstants";

import promiseDispatch from "./promiseDispatch";

export const NotificationActionCreators = {
    saveUserSettings: (settings: INotificationSettings) => async (dispatch: any, getState: any) => {
        promiseDispatch<boolean>(
            dispatch,
            () => NotificationAPI.saveNotificationSettings(settings),
            {
                request: NotificationActions.EDIT,
                success: NotificationActions.EDIT_SUCCESS,
                failure: NotificationActions.EDIT_FAILED
            });
    },

    getUserSettings: () => async (dispatch: any, getState: any) => {   
        promiseDispatch<INotificationViewSettings>(
            dispatch,
            NotificationAPI.getNotificationSettings,
            {
                request: NotificationActions.GET_SETTINGS,
                success: NotificationActions.GET_SETTINGS_SUCCESS,
                failure: NotificationActions.GET_SETTINGS_FAILED
            });
    },

    unRegisterMobileDevices: () => async (dispatch: any, getState: any) => {      
        promiseDispatch<INotificationViewSettings>(
            dispatch,
            NotificationAPI.unRegisterMobileDevices,
            {
                request: NotificationActions.UNREGISTER_MOBILE_REQUEST,
                success: NotificationActions.UNREGISTER_MOBILE_SUCCESS,
                failure: NotificationActions.UNREGISTER_MOBILE_FAILURE
            },
        );
    },
    languageSelectChange: (language: string) => ({ type: NotificationActions.LANGUAGE_CHANGE, payload: language }),
    reportTimeZoneChange: (timeZoneId: string) => ({ type: NotificationActions.REPORT_TIME_ZONE_CHANGE, payload: timeZoneId}),
    reportPeriodChange: (period: number) => ({ type: NotificationActions.REPORT_CHANGE_PERIOD, payload: period }),
    reportToggleChange: () => ({ type: NotificationActions.REPORT_TOGGLE }),
    reportBatteryReadingsChange: () => ({ type: NotificationActions.REPORT_BATTERY_READINGS_TOGGLE }),
    emailToggleChange: () => ({ type: NotificationActions.EMAIL_TOGGLE }),
    cancelChanges: () => ({ type: NotificationActions.EDIT_CANCEL }),
    showPhonesListDialog: () => ({ type: NotificationActions.SHOW_PHONES_LIST }),
    closePhonesListDialog: () => ({ type: NotificationActions.CLOSE_PHONES_LIST }),
    showReportCompanyFormDialog: () => ({ type: NotificationActions.SHOW_COMPANY_FORM }),
    closeReportCompanyFormDialog: () => ({ type: NotificationActions.CLOSE_COMPANY_FORM  }),
    
    showEmailListDialog: () => ({ type: NotificationActions.SHOW_EMAIL_LIST }),
    closeEmailListDialog: () => ({ type: NotificationActions.CLOSE_EMAIL_LIST }),
    reportTypeChange: (type: ReportTypes) => ({ type: NotificationActions.REPORT_CHANGE_TYPE, payload: type }),
 }