import * as React from "react";

export interface IUserContext {
    userName: string;
    isAuthenticated: boolean;
    isEmailConfirmed: boolean;
    isExternalAccount: boolean;
    isAdmin: boolean;
    isSupervisor: boolean;
    isDemoUser: boolean;
    applicationName: string;
    fullApplicationName: string;
    version: string;
    language: string;
    androidLink: string;
    documentationLink: string;
    redirectUrl: string;
    isLeftMenuExpanded: boolean;
    onlineSubscriptionEnabled: boolean;
    smogMapEnabled: boolean;
    externalAuthenticationEnabled: boolean;
    demoUserEnabled: boolean;
    style: string;
    serviceEmail: string;
    notifications: IUserNotification[];
    externalLogins: IAuthenticationScheme[];
    setLeftPanelState: (isExpand: boolean) => void;
    fetchUserInfo: () => void;
}

export interface IUserNotification {
    id: string;
    type: string;
    title: string;
    body: string;
    link: string;
    date: Date;
}

export interface IAuthenticationScheme {
    displayName: string;
    name: string;
}

export class UserInfoActions {
    public static readonly FETCH_USER = "FETCH_USER";
    public static readonly FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
    public static readonly FETCH_USER_FAILED = "FETCH_USER_FAILED";
    public static readonly CHANGE_LEFT_PANEL_STATE = "CHANGE_LEFT_PANEL_STATE";
    public static readonly SET_LEFT_PANEL_STATE_USER = "SET_LEFT_PANEL_STATE_USER";
    public static readonly SET_LEFT_PANEL_STATE_USER_SUCCESS = "SET_LEFT_PANEL_STATE_USER_SUCCESS";
    public static readonly SET_LEFT_PANEL_STATE_USER_FAILED = "SET_LEFT_PANEL_STATE_USER_FAILED";
}

const UserContext = React.createContext<IUserContext | null>(null);

export const UserContextProvider = UserContext.Provider;
export const UserContextConsumer = UserContext.Consumer;
export default UserContext;